<template>
  <v-theme-provider light>
    <base-section class="text-center" space="86">
      <span>
        <base-section-heading title="Payment" color="success"> </base-section-heading>
      </span>
      <v-responsive class="d-flex align-center mx-auto mb-4 background" height="100%" width="100%" max-width="100%">
        <!--https://codepen.io/travisw/pen/EvbKwd-->
        <div class="container">
          <div class="panel pricing-table">
            <div class="pricing-plan">
              <v-icon size="120" color="green lighten-1">{{ pricingIconProp }}</v-icon>
              <h2 class="pricing-header">{{ allProductsTitleComputed }}</h2>
              <span class="pricing-price">${{ totalAllProductsPricesSumComputed }}</span>
              <br />
              <span class="yearly-price">{{ allNamesAndPricesDescriptionComputed }}</span
              ><br />
              <div>
                <br />
                <v-btn :loading="showloading" @click="onStripeCheckout()" type="submit" :ripple="true" rounded color="green " dark raised>{{ subscriptionButtonNameProp }}</v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="fine-print">☆ One year commitment required. No increase within the same year regardless of the network's growth.</div>
      </v-responsive>
      <v-footer :fixed="true">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12">
            <v-alert v-model="showGenericFailure" :timeout="infinityTimeoutProp" dense dark color="black" class="align-top" type="error" prominent>
              <div class="text-center mt-2">
                <span class="body-2" v-html="genericFailureErrorMessage"></span>
              </div>
              <div class="text-center mt-1">
                <v-btn x-small color="white" class="black--text" @click="showGenericFailure = false">OK</v-btn>
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-footer>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { AlertTimeout } from '@/RegistrationStage.ts'
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX, envx } from '@/environments/EnvX.ts'
import { Subscription } from '@/Subscription.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  name: 'SubscribeSection',
  props: ['pricingPlanNameProp', 'pricingPlanDescriptionProp', 'pricingPlanPriceProp', 'stripeProductNamesProp', 'pricingIconProp', 'subscriptionButtonNameProp', 'successEndpointProp', 'cancelEndpointProp'],

  data: () => ({
    showloading: false,
    showGenericFailure: false,
    genericFailureErrorMessage: ''
  }),
  mounted() {
    this.showloading = false
  },

  computed: {
    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },

    allProductsTitleComputed() {
      return Subscription.allProductsTitleComputed(this.pricingPlanNameProp)
    },

    totalAllProductsPricesSumComputed() {
      return Subscription.totalAllProductsPricesSumComputed(this.pricingPlanPriceProp)
    },

    allNamesAndPricesDescriptionComputed() {
      return Subscription.allNamesAndPricesDescriptionComputed(this.pricingPlanDescriptionProp, this.pricingPlanPriceProp)
    }
  },

  methods: {
    onStripeCheckout() {
      const stripeCheckoutBody = {
        products: this.stripeProductNamesProp,
        webserverUrl: envx.webserverurl,
        successEndpoint: this.successEndpointProp,
        cancelEndpoint: this.cancelEndpointProp
      }

      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '💰 ComponentPricing.onStripeCheckout', // log hint
        endpoints.stripeCheckoutEndpoint, // endpoint
        environmentVariables.STRIPE_CHECKOUT_KEY, // code
        stripeCheckoutBody, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.showloading = true
          this.showGenericFailure = false
          this.genericFailureErrorMessage = ''

          return true
        },

        // onSuccess
        (data) => {
          this.showloading = false
          window.location = data.redirect
        },

        // All sort of errors
        (error, _unusedDescription) => {
          this.showloading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.loginAction()
          } else {
            this.showGenericFailure = true
            var errorMessage = error.message
            switch (error.message) {
              case 'StripeEngine.Deleted':
                errorMessage = 'Account deleted.'
                break
              case 'StripeEngine.Delinquent':
                errorMessage = 'Account looks suspended.'
                break
              case 'StripeEngine.Id':
                errorMessage = 'Account has inconsistencies.'
                break
              case 'StripeEngine.NotFound':
                errorMessage = 'Account not found.'
                break
            }

            const emailLink = '<a href="mailto:meagan@proinsight.com">Contact us</a>'
            errorMessage += `<br />${emailLink} for help!`
            this.genericFailureErrorMessage = errorMessage
          }
        }
      )
    },

    loginAction() {
      const currentRouteName = this.$route.name
      EnvX.log(`🌵 Current route: ${currentRouteName}`)
      this.$router.push({
        name: 'login', // push route name so that we can add params
        params: {
          successRoute: currentRouteName,
          failureRoute: 'homeroute'
        }
      })
    }
  }
}
</script>

<style scoped>
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.background::after {
  content: '';
  background: #a5eea8;
  background: -moz-linear-gradient(top, #a5eea8 0%, #108914 100%);
  background: -webkit-linear-gradient(top, #a5eea8 0%, #108914 100%);
  background: linear-gradient(to bottom, #a5eea8 0%, #108914 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#A5EEA8',
            endColorstr='#108914', GradientType=0);
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.container {
  margin: 0 auto;
  padding: 50px 0 0;
  max-width: 600px;
  width: 100%;
}

.panel {
  background-color: #fff;
  border-radius: 2px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 25px 50px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: #999;
  font-weight: 400;
  letter-spacing: 1.2px;
  font-size: 18px;
}

.pricing-features {
  color: #016ff9;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 50px 0 25px;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  list-style-type: none;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
  color: #5bbc5e;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 8px;
  margin-top: 8px;
  text-align: center;
}

.pricing-button {
  border: 1px solid #9dd1ff;
  border-radius: 10px;
  color: #348efe;
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-featured {
  background-color: #48aaff;
  color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  background-color: #269aff;
}

.fine-print {
  text-align: center;
  font-size: 12px;
  font-weight: 200;
  color: #888;
  padding-top: 15px;
}

.yearly-price {
  text-align: center;
  font-size: 12px;
  font-weight: 200;
  color: #888;
  padding-top: 15px;
}
</style>
