class Subscription {
  public static allProductsTitleComputed(pricingPlanNameProp: string[]): string {
    const separator = '+ '
    let accumulator = ''
    pricingPlanNameProp.forEach((productname) => {
      accumulator += `${productname} ${separator}`
    })
    return accumulator.slice(0, -(separator.length + 1))
  }

  public static totalAllProductsPricesSumComputed(pricingPlanPriceProp: number[]): number {
    let accumulator = 0
    pricingPlanPriceProp.forEach((price) => {
      accumulator += price
    })
    return accumulator
  }

  public static allNamesAndPricesDescriptionComputed(pricingPlanDescriptionProp: string[], pricingPlanPriceProp: number[]): string {
    const separator = '+ '
    const currencySymbol = '$'
    let accumulator = ''

    for (let i = 0; i < pricingPlanDescriptionProp.length; i++) {
      accumulator += `${pricingPlanDescriptionProp[i]} ${currencySymbol}${pricingPlanPriceProp[i].toString()} ${separator}`
    }
    return accumulator.slice(0, -(separator.length + 1))
  }
}

export { Subscription }
